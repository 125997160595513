import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';

const StyledButton = styled.button`
  background: ${props => props.primary ? 'var(--primaryColor)' : 'transparent'};
  color: ${props => props.dark ? 'var(--darkColor)' : 'var(--lightColor)'};
  // min-width: ${props => props.small ? props.circle ? '64px' : '125px' : '220px'};
  padding: ${props => props.small ? 'var(--defaultSpacing) calc(2*var(--defaultSpacing))' : 'calc(2*var(--defaultSpacing)) calc(4*var(--defaultSpacing))'};
  // height: 60px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid ${props => props.primary ? 'var(--primaryColor)' : props.dark ? '#eee' : 'var(--lightColor)'};
  margin-right: ${props => props.nopadding ? 0 : 'var(--defaultSpacing)'};
  margin-bottom: ${props => props.nopadding ? 0 : 'var(--defaultSpacing)'};
  transition: all .2s ease-in-out;
  cursor: pointer;
  box-shadow: 0px 6px 14px -12px #aaa;
  outline: 0;
  white-space: nowrap;

  &:hover {
    box-shadow: 0px 12px 24px -6px #ccc;
    transform: translateY(-0.25em);
    background: white;
    color: #333;
  }

  &:active { 
    transform: translateY(0em);
  }

  :disabled {
    filter: grayscale(1);
    opacity: ${({ active }) => active ? 1 : .25};
    cursor: not-allowed;
    transform: none;
    background: ${({ active, dark }) => active ? (dark ? '#646468' : '#fff') : 'transparent'};
    color: ${({ active, dark }) => active || dark ? (dark ? 'var(--lightColor)' : 'var(--darkColor)') : 'var(--lightColor)'};
    border-color: #595964;

    &:hover {
      box-shadow: 0px 6px 18px -12px #000;
      // background: ${props => props.primary ? 'var(--primaryColor)' : 'transparent'};
      // color: ${props => props.dark ? 'var(--darkColor)' : 'var(--lightColor)'};
    }
  }
`

const Button = (props) => {
  const { href, to, ref, ...rest } = props;

  const external = to && (to.indexOf('http://') === 0 || to.indexOf('https://') === 0);

  const content = <StyledButton {...rest}>
    {props.children}
  </StyledButton>

  if (href) {
    return <AnchorLink ref={ref} href={href}>
      {content}
    </AnchorLink>
  }

  if (to) {
    if (external) {
      return <a ref={ref} href={to} target="_blank">
        {content}
      </a>
    } else {
      return <Link ref={ref} to={to}>
        {content}
      </Link>
    }
  }

  return content;
}

export default Button;
