import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faFacebookSquare,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';

import { 
    faCheck,
    faEnvelope,
    faMobileAlt,
    faPhone,
    faArrowCircleUp,
    faCookieBite,
    faCheckCircle,
    faTimes,
    faSpinner,
    faArrowLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircleNotch,
    faPlayCircle,
    faLink,
    faPen,
    faUnlock,
    faLock,
    faBookmark,
    faHeading,
    faFont,
    faParagraph,
    faBold,
    faItalic,
    faUnderline,
    faSuperscript,
    faSubscript,
    faListOl,
    faListUl
} from '@fortawesome/free-solid-svg-icons';

const icons = {
    faCheck,
    faEnvelope,
    faMobileAlt,
    faPhone,
    faArrowCircleUp,
    faCookieBite,
    faCheckCircle,
    faTimes,
    faSpinner,
    faArrowLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircleNotch,
    faPlayCircle,
    faLink,
    faPen,
    faUnlock,
    faLock,
    faBookmark,
    faHeading,
    faFont,
    faParagraph,
    faBold,
    faItalic,
    faUnderline,
    faSuperscript,
    faSubscript,
    faListOl,
    faListUl,
    faFacebookSquare,
    faYoutube
}

export default ({ name, ...rest }) => {
    return <FontAwesomeIcon icon={icons[name]} {...rest} />
}