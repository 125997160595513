import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from './components/header';
import ContentWrapper from './components/layout/contentWrapper';
import routes from './routes';
import Button from './components/button';
import Value from './Value';
import Icon from './icon';

let scrollHistory = {};
let lastLocation = 0;


function ScrollToTop({ history, children }) {

  const cookieDisclaimerStorage = document.cookie.split('; ').find(row => row.startsWith('eu_cookie_law'));

  const [pathname, setPathname] = useState(history.location.pathname);
  const [cookieDisclaimerVisibilty, setCookieDisclaimerVisibilty] = useState(cookieDisclaimerStorage !== 'eu_cookie_law=accept')

  useEffect(() => {
    // window.onpopstate = (e) => {
    //   scrollHistory[history.location.pathname] = window.scrollY;
    //   // setTimeout(() => {
    //   //   window.scrollTo({ top: 0 });
    //   // }, 50);
    // };

    lastLocation = history.location.pathname;
    const unlisten = history.listen(() => {
      const { pathname: pathnameNew } = history.location;

      if (history.action === 'PUSH') {
        scrollHistory[pathname] = window.scrollY;
        window.scrollTo({ top: 0 });
      }

      if (history.action === 'POP') {
        setTimeout(() => {
          window.scrollTo({ top: 0 });
          setTimeout(() => {
            window.scrollTo({ top: scrollHistory[pathname] || 0, behavior: 'smooth' });
          }, 100);
        }, 50);
      }

      setPathname(pathnameNew);
    });

    return () => {
      unlisten();
    }
  }, []);

  let activeRoutes = routes.filter(r => !r.hiddenBottom);

  return <>
    <Header pathname={pathname} />
    {pathname === '/' ? children :
      <div style={{ paddingTop: '5rem' }}>
        {children}
      </div>
    }

    <div style={{ /*background: 'radial-gradient(circle, rgb(21,21,22) 0%, rgb(12,12,12) 100%)',*/ fontSize: '.8rem' }}>
      <div style={{ background: 'radial-gradient(circle, rgb(31,31,32) 0%, rgb(21,21,22) 100%)' }}>

        {pathname === '/contact-us' || pathname === '/404' ? null :
          <ContentWrapper padding="calc(2*var(--extraSpacing))">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fff', fontSize: 'var(--headlineFontSize)', flexWrap: 'wrap' }}>
              <div style={{ flex: 1, minWidth: '300px' }}>
                <div style={{ fontSize: 'var(--descriptionFontSize)', fontWeight: 'normal' }}>
                  <Value pageId="footer" id="banner1" type="title" />
                  {/* Ready to get started? */}
                </div>
                <p style={{ marginBottom: 'var(--defaultSpacing)', fontWeight: 'lighter' }}>
                  <Value pageId="footer" id="banner1" type="text" />
                  {/* Don't lose time and get more information face to face. */}
                </p>
              </div>
              {/* <div style={{ paddingRight: '1rem' }}>+49 151 58965825</div> */}
              <Button to="/contact-us">Contact us</Button>
              {/* <div style={{ fontSize: '1rem', lineHeight: '1rem', borderRadius: '1rem', background: '#1e1e1e', padding: '1rem 2rem' }}>CONTACT US</div> */}
            </div>
          </ContentWrapper>}
      </div>

      <ContentWrapper padding="calc(2*var(--extraSpacing))">


        <div style={{ width: '100%', display: 'flex', paddingBottom: '2rem', flexWrap: 'wrap', justifyContent: 'space-between', color: '#efefef', borderBottom: '1px solid #414141', textTransform: 'uppercase' }}>

          <div style={{ paddingRight: '3rem', marginTop: '1rem' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/nf-aircraft-gmbh.appspot.com/o/images%2Flogo-nf-aircraft.svg?alt=media&token=357acb09-500c-4fe5-951c-2b5d6ee0dfa5" style={{ width: '15rem', opacity: .75 }} />
          </div>




          <div style={{ flex: 1, minWidth: '250px' }}>
            <p>
              <b>NF-aircraft GmbH</b><br />
              <span>Norderheverkoog West 8<br />25836 Osterhever<br />Germany</span>
            </p>

          </div>



          {/* <div className="hideOnMobile" style={{ flex: 1 }}>

              <div style={{ display: 'flex', flexWrap: 'wrap', fontSize: '2rem' }}>
                {activeRoutes.map((r, i) => <Link to={r.to}><div style={{ color: '#efefef', fontSize: '.8rem' }}>{r.title}&nbsp;{i < activeRoutes.length - 1 ? (<b>|</b>) : ''}&nbsp;</div></Link>)}
              </div>
            </div> */}


          <div style={{ flex: 1, paddingRight: '1rem', minWidth: '250px', marginTop: '1rem', color: '#fff' }}>
            <p>
              <a href="mailto:info@nf-aircraft.com" style={{ color: '#fff' }}><Icon name="faEnvelope" style={{ marginRight: '1rem' }} />info@nf-aircraft.com</a><br />
              <a href="mailto:sales@nf-aircraft.com" style={{ color: '#fff' }}><Icon name="faEnvelope" style={{ marginRight: '1rem' }} />sales@nf-aircraft.com</a><br />
              <a href="tel:+4915158965825" style={{ color: '#fff' }}><Icon name="faMobileAlt" style={{ marginRight: '1rem' }} />+49 151 58965825</a><br />
              <a href="tel:+4948659019588" style={{ color: '#fff' }}> <Icon name="faPhone" style={{ marginRight: '1rem' }} />+49 4865 9019588</a><br />
            </p>
          </div>



          {/* <div style={{ flex: 1 }}>
              {routes.slice(0, (routes.length / 2) + 0).map(r => <Link to={r.to}><div style={{ color: '#fff' }}>{r.title}</div></Link>)}
            </div>

            <div style={{ flex: 1 }}>
              {routes.slice((routes.length / 2) + 0).map(r => <Link to={r.to}><div style={{ color: '#fff' }}>{r.title}</div></Link>)}
            </div> */}
          <div style={{ fontSize: '1.75rem', paddingTop: '0rem', maxWidth: 'calc(100vw - 1rem)', textAlign: 'right', marginTop: '1rem' }}>


            <div style={{ fontSize: '.75rem', cursor: 'pointer' }} onClick={() => (window.scrollTo({ top: 0, behavior: 'smooth' }))}>
              <Icon name="faArrowCircleUp" /> Back to Top</div>
            <div style={{ fontSize: '.75rem' }}>
              <Link to="/privacy-statement" style={{ color: '#fff' }}>Privacy statement</Link>
            </div>
            <div style={{ fontSize: '.75rem' }}>
              <Link to="/imprint" style={{ color: '#fff' }}>Imprint</Link>
            </div>

          </div>

        </div>


        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', color: '#a4a4a4', fontSize: '.75rem', marginTop: '2rem' }}>
          <span style={{ color: '#a4a4a4', margin: '0 .5rem' }}>
            © 2020 NF-aircraft GmbH, all rights reserved
            </span>
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', color: '#efefef', paddingTop: '.5rem', fontSize: '.9rem' }}>
          <div style={{ fontSize: '.75rem', color: '#a4a4a4', textTransform: 'uppercase' }}>Follow us on Social Media</div>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', color: '#efefef', paddingBottom: '1rem', fontSize: '.9rem' }}>
          <div style={{ fontSize: '1.75rem', maxWidth: 'calc(100vw - 3rem)' }}>
            <a href="https://www.facebook.com/Explorer-1841666912603213" target="_blank">
              <Icon name="faFacebookSquare" style={{ marginRight: '1rem', cursor: 'pointer', color: '#a4a4a4' }} />
            </a>
            <a href="https://www.youtube.com/channel/UCXXzOOHCkUXwSnTgD9Ypf1g" target="_blank">
              <Icon name="faYoutube" style={{ cursor: 'pointer', color: '#a4a4a4' }} />
            </a>
          </div>
        </div>


      </ContentWrapper>

      {cookieDisclaimerVisibilty ?
        <div style={{ display: 'flex', alignItems: 'center', width: '100vw', position: 'fixed', bottom: 0, justifyContent: 'space-between', display: 'flex', background: '#222', color: '#fff', zIndex: 1000 }}>
          <div style={{ margin: '1rem 2rem' }}>
            <Icon name="faCookieBite" style={{ fontSize: '.75rem' }} />&nbsp;
              Cookies help us give you a better experience on this website. By continuing to use our site, you are agreeing to the use of cookies as set in our
              &nbsp;
            <Link to="/privacy-statement" style={{ textDecoration: 'underline', color: '#fff' }}>Cookie Policy</Link>.
          </div>
          <div style={{ margin: '1rem 2rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
            let expire = new Date();
            expire.setFullYear(expire.getFullYear() + 100);
            document.cookie = `eu_cookie_law=accept; expires=${expire.toUTCString()}`;
            setCookieDisclaimerVisibilty(false);
          }}>
            <Icon name="faCheckCircle" style={{ fontSize: '1.5rem' }} />
          </div>
        </div> : null}

    </div>
  </>;
}

export default withRouter(ScrollToTop);