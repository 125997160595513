import styled from 'styled-components';

export default styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: ${props => props.padding || (props.align === 'center' ? '0 var(--extraSpacing)' : 'calc(4*var(--extraSpacing)) calc(2*var(--extraSpacing))')};
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  align-items: ${props => props.align || 'flex-start'};
  min-height: ${props => props.minHeight || 'auto'};
`;