import React, {createContext, useReducer} from 'react';
import Auth from './auth';

const initialState = {
  auth: false,
  meta: null
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'signIn':
        return {
          auth: action.data.isAnonymous ? false : true,
          meta: action.data
        };
      case 'signOut':
        return initialState;
    };
  }, initialState);

  if (!state.meta) {
    Auth.signInAnonymously();    
  }

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};


// console.log(store);



export { store, StateProvider }