import React from 'react';
import Icon from './icon';

export default () => {
    return (
        <div>
            <div class="wrapper">
                <div>
                    <div class="logo">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" width="354.076px" height="82.099px" viewBox="0 0 354.076 82.099"
            enable-background="new 0 0 354.076 82.099">
            <g>
              <g>
                <path fill="#424246"
                  d="M136.355,59.279c0.808-1.574,1.161-3.112,1.048-4.598c-0.147-1.509-0.802-2.742-1.951-3.663
         c-1.009-0.801-2.35-1.367-3.983-1.684c-1.567-0.304-3.733-0.452-6.622-0.452h-24.623l-1.274,2.484l1.269,0.307
         c0.138,0.033,0.249,0.065,0.335,0.096L87.688,76.86c-0.135,0.264-0.31,0.456-0.537,0.595c-0.199,0.121-0.607,0.298-1.41,0.453
         l-0.442,0.084l-0.023,0.048l-1.115-0.142c-0.99-0.123-1.339-0.361-1.445-0.459c-0.127-0.119-0.508-0.561-1.278-2.133
         l-5.272-10.633l15.678-10.047c2.899-1.828,3.892-2.394,4.216-2.545c0.28-0.133,0.832-0.307,1.87-0.387l0.509-0.04l1.42-2.772
         h-17.25l-1.342,2.619l1.427,0.188c0.063,0.009,0.121,0.018,0.174,0.026c-0.292,0.237-0.765,0.583-1.518,1.075l-8.402,5.397
         l-2.471-4.982c-0.214-0.444-0.271-0.688-0.286-0.789c0.138-0.191,0.536-0.526,1.658-0.733l0.446-0.083l1.392-2.718H56.001
         l-1.276,2.703l1.459,0.109c1.137,0.084,1.402,0.293,1.41,0.301c0.034,0.031,0.357,0.36,1.245,2.218l4.861,9.914L46.928,74.903
         c-2.131,1.38-3.514,2.224-4.109,2.509c-0.332,0.16-0.721,0.277-1.153,0.363l2.807-5.476h-2.945l-0.281,0.32
         c-0.37,0.425-0.707,0.714-1,0.861c-0.279,0.14-0.638,0.212-1.065,0.212H16.015l3.087-6.022h15.099c0.182,0,0.31,0.013,0.394,0.028
         c-0.002,0.04-0.007,0.087-0.015,0.142l-0.173,1.223h2.57l4.935-9.626h-2.945l-0.28,0.322c-0.462,0.528-0.798,0.758-1.001,0.86
         c-0.28,0.14-0.638,0.212-1.064,0.212H22.609l2.53-4.935h23.574c0.182,0,0.31,0.013,0.396,0.028
         c-0.003,0.037-0.007,0.084-0.016,0.135l-0.181,1.229h2.578l5.024-9.798H53.57l-0.28,0.318c-0.369,0.418-0.706,0.707-1.005,0.858
         c-0.279,0.144-0.637,0.214-1.063,0.214H16.315l-1.273,2.484l1.268,0.307c0.139,0.033,0.25,0.065,0.334,0.096L3.792,76.837
         c-0.144,0.28-0.323,0.481-0.549,0.618c-0.2,0.121-0.606,0.298-1.41,0.453l-0.442,0.084L0,80.707h36.671
         c0.182,0,0.311,0.012,0.397,0.027c-0.003,0.035-0.008,0.078-0.016,0.129l-0.189,1.235h2.584l0.715-1.392h15.736l1.386-2.703
         l-1.52-0.109c-0.029-0.002-0.057-0.005-0.083-0.007c0.249-0.198,0.634-0.481,1.224-0.87l9.98-6.396l3.048,6.219
         c0.131,0.272,0.186,0.444,0.208,0.539c-0.164,0.161-0.604,0.395-1.507,0.521l-0.47,0.066l-1.404,2.74h17.148h0.341h13.886
         l1.286-2.508l-1.296-0.288c-0.133-0.028-0.239-0.059-0.321-0.084l4.705-9.177h13.29c3.61,0,6.618-0.282,8.94-0.841
         c2.363-0.566,4.55-1.538,6.504-2.89C133.535,63.322,135.255,61.426,136.355,59.279z M117.321,61.637h-11.216l2.943-5.739h12.301
         c3.181,0,4.289,0.332,4.661,0.526c0.279,0.149,0.36,0.326,0.395,0.546c0.057,0.352-0.042,0.775-0.292,1.261
         c-0.306,0.599-0.769,1.162-1.377,1.675c-0.622,0.522-1.286,0.91-1.97,1.146C121.608,61.44,119.776,61.637,117.321,61.637z" />
                <path fill="#424246" d="M166.559,72.62c-0.371,0.423-0.707,0.714-1.001,0.861c-0.28,0.14-0.647,0.212-1.092,0.212H142.4
         l10.736-20.94c0.144-0.282,0.328-0.488,0.562-0.634c0.197-0.123,0.597-0.297,1.382-0.435l0.452-0.079l1.396-2.723h-14.227
         l-1.273,2.484l1.268,0.307c0.14,0.033,0.249,0.065,0.335,0.096l-12.853,25.067c-0.143,0.279-0.323,0.481-0.548,0.618
         c-0.201,0.121-0.607,0.298-1.41,0.453l-0.443,0.084l-1.391,2.715h35.569c0.198,0,0.333,0.014,0.426,0.028
         c-0.003,0.036-0.009,0.078-0.017,0.128l-0.187,1.235h2.583l5.024-9.799h-2.945L166.559,72.62z" />
                <path fill="#424246" d="M218.343,65.503c2.609-5.089,2.413-9.339-0.574-12.629c-2.717-3.036-7.467-4.513-14.531-4.513
         c-9.167,0.09-17.025,2.575-23.361,7.389c-3.349,2.546-5.853,5.408-7.441,8.51c-1.122,2.188-1.681,4.321-1.663,6.348
         c0.025,2.366,0.809,4.422,2.327,6.109c2.714,3.035,7.437,4.512,14.437,4.512c9.049,0,16.934-2.493,23.436-7.409
         C214.34,71.274,216.82,68.476,218.343,65.503z M205.267,68.123c-1.441,1.377-3.123,2.56-4.998,3.514
         c-3.152,1.608-6.557,2.426-10.115,2.426c-3.445,0-5.9-0.75-7.301-2.232c-0.98-1.033-2.067-2.831-0.181-6.511
         c1.54-3.006,4.206-5.482,7.924-7.366c3.172-1.61,6.565-2.426,10.086-2.426c3.438,0.027,5.908,0.783,7.349,2.248
         c0.989,0.995,2.076,2.767,0.098,6.625C207.48,65.667,206.516,66.92,205.267,68.123z" />
                <path fill="#424246" d="M256.8,77.893c-0.609-0.031-1.068-0.174-1.289-0.402c-0.139-0.143-0.537-0.666-1.252-2.43l-2.755-6.89
         c6.993-1.044,11.792-4.025,14.276-8.87c0.816-1.592,1.195-3.134,1.13-4.593c-0.092-1.626-0.856-2.977-2.209-3.901
         c-1.052-0.727-2.454-1.238-4.166-1.521c-1.627-0.267-3.958-0.403-6.931-0.403h-24.68l-1.273,2.484l1.268,0.307
         c0.14,0.033,0.25,0.065,0.335,0.096L216.39,76.86c-0.135,0.264-0.311,0.456-0.537,0.595c-0.199,0.121-0.606,0.298-1.409,0.453
         l-0.442,0.084l-1.391,2.715h14.227l1.285-2.508l-1.297-0.288c-0.133-0.028-0.238-0.059-0.32-0.084l4.738-9.243h9.109l3.076,8.092
         c0.186,0.5,0.268,0.811,0.306,0.99c-0.153,0.07-0.456,0.169-1.008,0.232l-0.486,0.054l-1.411,2.755h16.124l1.401-2.734
         L256.8,77.893z M237.749,55.897h12.442c1.915,0,3.236,0.038,3.926,0.114c0.605,0.068,1.127,0.219,1.563,0.458
         c0.241,0.128,0.385,0.268,0.427,0.414c0.035,0.126,0.071,0.505-0.35,1.327c-0.678,1.321-1.741,2.215-3.252,2.732
         c-0.735,0.253-1.496,0.421-2.262,0.501c-0.802,0.084-2.162,0.127-4.047,0.127h-11.357L237.749,55.897z" />
                <path fill="#424246" d="M299.128,72.3l-0.281,0.32c-0.369,0.423-0.705,0.713-0.999,0.86c-0.28,0.141-0.639,0.213-1.065,0.213
         h-23.166l3.088-6.022h15.098c0.183,0,0.311,0.013,0.395,0.028c-0.004,0.039-0.008,0.084-0.016,0.141l-0.173,1.224h2.571
         l4.934-9.626h-2.945l-0.279,0.32c-0.461,0.529-0.799,0.76-1.001,0.86c-0.279,0.142-0.638,0.213-1.064,0.213H280.21l2.529-4.934
         h23.574c0.182,0,0.311,0.013,0.396,0.028c-0.003,0.036-0.007,0.084-0.016,0.135l-0.182,1.229h2.578l5.024-9.8h-2.943l-0.28,0.32
         c-0.368,0.418-0.706,0.707-1.005,0.858c-0.279,0.143-0.637,0.214-1.063,0.214h-34.907l-1.272,2.484l1.268,0.307
         c0.139,0.033,0.249,0.065,0.334,0.096l-12.852,25.067c-0.144,0.279-0.323,0.481-0.549,0.618c-0.2,0.121-0.607,0.298-1.41,0.453
         l-0.442,0.084l-1.391,2.715h36.671c0.182,0,0.311,0.012,0.396,0.027c-0.004,0.035-0.008,0.078-0.016,0.129l-0.189,1.235h2.585
         l5.024-9.799H299.128z" />
                <path fill="#424246" d="M354.068,54.708c-0.091-1.626-0.856-2.977-2.209-3.902c-1.052-0.726-2.454-1.237-4.166-1.52
         c-1.626-0.267-3.957-0.403-6.93-0.403h-24.68l-1.274,2.484l1.269,0.307c0.139,0.033,0.249,0.065,0.334,0.096L303.548,76.86
         c-0.135,0.264-0.311,0.456-0.537,0.595c-0.199,0.121-0.607,0.298-1.41,0.453l-0.442,0.084l-1.392,2.714h14.228l1.286-2.507
         l-1.297-0.288c-0.133-0.028-0.239-0.059-0.32-0.084l4.737-9.243h9.11l3.076,8.092c0.184,0.5,0.268,0.811,0.305,0.99
         c-0.153,0.07-0.455,0.169-1.007,0.232l-0.487,0.054l-1.411,2.754h16.124l1.4-2.733l-1.553-0.08
         c-0.61-0.031-1.068-0.174-1.291-0.402c-0.138-0.143-0.535-0.666-1.25-2.43l-2.756-6.89c6.994-1.046,11.793-4.025,14.275-8.87
         C353.754,57.708,354.134,56.167,354.068,54.708z M324.906,55.897h12.443c1.915,0,3.236,0.038,3.925,0.114
         c0.605,0.068,1.128,0.219,1.563,0.458c0.24,0.128,0.385,0.268,0.428,0.414c0.034,0.126,0.071,0.505-0.35,1.327
         c-0.678,1.321-1.742,2.215-3.253,2.732c-0.735,0.253-1.497,0.421-2.263,0.501c-0.8,0.084-2.162,0.127-4.046,0.127h-11.356
         L324.906,55.897z" />
              </g>
              <g>
                <g>
                  <path fill-rule="evenodd" clip-rule="evenodd" fill="#424246"
                    d="M16.991,43.693c32.404,0.184,53.468-14.213,81.115-20.148
           c-0.4,2.861-4.065,3.597-6.377,4.507c-7.797,3.079-17.407,5.413-25.069,8.52c9.828,1.681,18.932-1.364,27.23-3.11
           c1.053,1.102-0.648,2.352-0.336,3.951c1.819-1.098,4.686-0.905,6.917-2.061c1.63-0.846,2.239-2.545,3.677-3.598
           c1.25-0.918,3.086-1.094,4.285-1.941c4.709-3.324,7.255-6.648,12.314-8.267c12.122-3.876,28.092-0.562,40.764,5.092
           c-5.554,4.062-12.822,6.04-20.21,8.631c-14.267,5.006-32.762,5.515-49.973,6.293c-8.573,0.387-17.654-0.612-24.632,3.381
           c42.365,0.989,79.897-1.804,109.968-15.913c9.754-4.575,18.278-13.582,31.344-11.842c-3.371,9.809-19.599,6.854-28.477,13.341
           c-2.69,1.964-7.073,6.101-7.252,10.396c1.83,0.399,3.452-2.005,5.534-3.403c2.348-1.577,4.827-3.795,7.152-4.826
           c7.199-3.19,17.535-3.62,26.387-6.386c3.375-1.055,7.339-3.579,10.999-6.016c2.789-1.857,8.4-6.346,10.628-6.053
           c3.063,0.403,4.014,4.855,7.322,6.351c4.063,1.834,7.109,0.31,10.665,2.319c0.064,0.829-3.466,0.791-3.61,2.808
           c12.795,2.247,27.063,3.087,39.752,3.791c-2.932-3.323-7.677-2.633-11.644-4.019c-1.405-0.49-2.462-1.812-3.915-2.404
           c-5.731-2.341-12.389-2.722-18.694-4.76c-0.971-0.854-0.609-2.875-1.149-4.107c5.463-0.78,10.014,4.807,15.188,6.383
           c3.955,1.205,8.43,0.852,12.586,1.726c4.992,1.05,11.064,3.698,17.143,5.392c10.473,2.921,19.87,2.885,24.67,9.375
           c-10.354,0.854-21.754,0.364-29.93,4.02c8.305,2.1,18.752,1.103,27.873,2.539c2.129,0.335,4.075,1.749,6.176,2.244
           c8.391,1.975,17.459,1.337,26.018,2.342c-5.453-5.285-13.183-5.566-19.811-9.261c-3.893-2.167-6.621-6.025-10.159-8.244
           c-3.642-2.282-8.962-3.422-13.802-5.041c-9.027-3.019-17.719-7.397-26.02-11.108c-5.036-2.253-10.319-3.105-14.512-5.515
           c-4.217-2.425-8.699-6.728-14.209-9.069c-2.585,1.576-5.287,0.304-8.366,1.511c-1.876,0.733-2.923,2.944-4.792,3.479
           c-4.822,1.383-10.359,0.582-15.15,1.99c-3.498,1.03-6.143,3.1-9.313,3.803c-10.539,2.332-21.779,0.894-32.258,4.971
           c-2.89,2.565-6.636,4.028-9.885,6.133c-6.983-0.846-13.158-5.896-19.54-8.037c-6.5-2.182-13.526-1.623-19.404-5.232
           c-8.605,0.087-15.16,5.432-22.607,5.987c-2.888,0.214-6.211-0.974-8.908-0.939c-6.627,0.086-14.443,4.722-21.223,7.33
           c-14.557,5.598-26.958,10.937-41.4,15.563c-7.432,2.383-14.636,4.367-22.235,6.027C17.363,42.712,17.029,43.011,16.991,43.693z" />
                </g>
              </g>
            </g>
          </svg>

                    </div>
                    <div class="loaderWrapper">
                        <Icon name="faCircleNotch" spin size="3x" />
                    </div>
                </div>
            </div>
        </div>
    );
}