import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBOIldyg-3H_HHjhb2ozo1b3Ws8dqmuPf0",
    authDomain: "nf-aircraft-gmbh.firebaseapp.com",
    databaseURL: "https://nf-aircraft-gmbh.firebaseio.com",
    projectId: "nf-aircraft-gmbh",
    storageBucket: "nf-aircraft-gmbh.appspot.com",
    messagingSenderId: "334462288944",
    appId: "1:334462288944:web:0349b6271dda0b814799f5",
    measurementId: "G-YEZN88BJQT"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;

export const authInstance = firebase.auth();
export const storageInstance = firebase.storage();
export const firestoreInstance = firebase.firestore();
export const CACHE_SIZE_UNLIMITED = firebase.firestore.CACHE_SIZE_UNLIMITED;

/**
 * 
 * 
 * <!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="/__/firebase/7.15.5/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->

<!-- Initialize Firebase -->
<script src="/__/firebase/init.js"></script>
 * 
 * 
 */