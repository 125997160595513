import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Burger from './burger';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';
import routes from '../routes';
import { store } from '../userContext';
import Icon from '../icon';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  background-color: ${props => props.scrollTop ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,1)'};
  box-shadow: ${props => props.scrollTop ? 'none' : '0 1px 1px rgba(0, 0, 0, 0.01), 0 10px 30px rgba(0,0,0,0.08)'};
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;
  border-bottom: ${props => props.scrollTop ? '1px solid transparent' : '1px solid #eee'};
  transition: all .25s ease-in-out;
`

const MenuLink = styled.div`
  font-size: 1.5rem;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
  cursor: pointer;

  transition: all .2s ease-in-out;
  text-decoration: none;

  &:hover {
    font-weight: bold;
  }
`

/*

The story
About company
Interior
Technical spec
Construction

-----

Contact us
Media icon

*/

const Header = (props) => {
  const { pathname } = props;
  const indexPage = pathname === '/' ? true : false;
  const [scrollTop, setScrollTop] = useState(indexPage ? true : false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { state: { auth } } = useContext(store);

  useEffect(() => {
    const onScroll = e => {
      if (e.target && e.target.documentElement && e.target.documentElement.scrollTop > 0 && scrollTop) {
        setScrollTop(false);
      }

      if (e.target && e.target.documentElement && e.target.documentElement.scrollTop < 1 && !scrollTop) {
        setScrollTop(indexPage ? true : false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    setScrollTop(indexPage ? true : false);
  }, [pathname])

  const transparentMenu = menuOpen ? false : scrollTop;
  const currentRoute = routes.find(r => r.to === pathname);

  return <>
    <StyledHeader scrollTop={transparentMenu}>
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        style={{ display: 'flex', alignItems: 'center', margin: '0 calc(2*var(--extraSpacing))', fontSize: '1rem', letterSpacing: -.5, color: transparentMenu ? '#fff' : '#333', flex: 1, cursor: 'pointer', textTransform: 'uppercase' }}>
        {menuOpen ? <Icon name="faArrowLeft" style={{ fontSize: '1.25rem', marginRight: '1rem' }} /> : <Burger darkMode={transparentMenu} />}
        {menuOpen ? currentRoute ? `NAVIGATION` : 'CLOSE' : (pathname === '/' ? '' : (currentRoute ? currentRoute.title : 'Not found'))}
      </div>

      <div className="hideOnMobile" style={{ marginRight: '2rem', textTransform: 'uppercase', color: transparentMenu ? '#fff' : '#333', fontSize: '1rem', flex: 1, textAlign: 'right', lineHeight: '1rem', borderRight: '1px solid #ccc', paddingRight: '2rem' }}>
        <span style={{ color: transparentMenu ? '#eee' : '#888' }}>call us</span><br /><b>+49 151 58965825</b>
      </div>
      <AnchorLink href="#top">
        <Link to="/">
          <div style={{ display: 'flex', alignItems: 'center', filter: transparentMenu ? 'invert(1)' : 'invert(0)', cursor: transparentMenu ? 'default' : 'pointer' }}>
            <img src="https://firebasestorage.googleapis.com/v0/b/nf-aircraft-gmbh.appspot.com/o/images%2Flogo-nf-aircraft.svg?alt=media&token=357acb09-500c-4fe5-951c-2b5d6ee0dfa5" style={{ height: '3.5rem', top: '0rem', marginRight: 'calc(2*var(--extraSpacing))', position: 'relative', filter: 'invert(1)', opacity: .75 }} />
          </div>
        </Link>
      </AnchorLink>

    </StyledHeader>
    {
      menuOpen ?
        <div onClick={() => setMenuOpen(false)} style={{ position: 'fixed', top: 0, width: '100vw', height: '100vh', opacity: '.5', background: '#000', zIndex: 98 }}>
          &nbsp;
  </div> : null}
    <div style={{ background: '#fff', color: '#333', position: 'fixed', top: '0', marginTop: menuOpen ? '0' : '-200%', opacity: menuOpen ? 1 : 0, width: '100vw', zIndex: 99, transition: 'all .35s ease-out', overflow: 'hidden', borderBottom: menuOpen ? '1px solid #eee' : 'none' }}>
      <div style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
        <div style={{ margin: 'calc(2*var(--extraSpacing))', marginTop: '8rem' }}>
          {routes.filter(r => !r.hiddenTop).map(l => <Link to={l.to} onClick={() => setMenuOpen(false)}><MenuLink>{l.title}</MenuLink></Link>)}
        </div>
        <div style={{ margin: 'calc(2*var(--extraSpacing))', fontSize: '1.75rem', color: '#333', padding: '2rem 0', borderTop: '1px solid #eee', maxWidth: 'calc(100vw - 3rem)', display: 'flex', justifyContent: 'space-between' }}>
          <div>

            <div style={{ fontSize: '1.25rem', color: '#424243'}}>
              <a href="https://www.facebook.com/Explorer-1841666912603213" target="_blank">
                <Icon name="faFacebookSquare" style={{ marginRight: '1rem', cursor: 'pointer', color: '#424243' }} />
              </a>
              <a href="https://www.youtube.com/channel/UCXXzOOHCkUXwSnTgD9Ypf1g" target="_blank">
                <Icon name="faYoutube" style={{ marginRight: '1rem', cursor: 'pointer', color: '#424243' }} />
              </a>
              |&nbsp;&nbsp;
              Follow us on Social Media
            </div>

          </div>

          <div>
            <Link to="/auth" onClick={() => setMenuOpen(false)}>
              <Icon name={auth ? 'faUnlock' : 'faLock'} style={{ fontSize: '.75rem', color: 'var(--grayColor)', opacity: 0.5 }} />
            </Link>
          </div>
        </div>

      </div>
    </div>
  </>
}

export default Header;
