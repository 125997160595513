import React from 'react';
import styled from 'styled-components';

const StyledBurger = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1rem;
  margin-right: 1rem;
  cursor: pointer;
`

const StyledBar = styled.div`
  position: absolute;
  height: .15rem;
  width: 100%;
  right: 0; 
  background: ${props => props.darkMode ? '#fff' : '#333'};

  &.top {
    top: 0rem;
  }

  &.middle {
   top: .5rem;
  }

  &.bottom {
    top: 1rem;
  }
`

const Burger = (props) => {
  const { darkMode } = props;

  return <StyledBurger>
    <StyledBar className="top" darkMode={darkMode} />
    <StyledBar className="middle" darkMode={darkMode} />
    <StyledBar className="bottom" darkMode={darkMode} />
  </StyledBurger>
}

export default Burger;
