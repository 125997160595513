import React from 'react';


const Home = React.lazy(() => import('./pages/Home'));
const TheStory = React.lazy(() => import('./pages/TheStory'));
const AboutCompany = React.lazy(() => import('./pages/AboutCompany'));
const DimensionsAndSpecification = React.lazy(() => import('./pages/DimensionsAndSpecification'));
const InteriorDesign = React.lazy(() => import('./pages/InteriorDesign'));
const BusinessOpportunity = React.lazy(() => import('./pages/BusinessOpportunity'));
const AircraftForSaleGallery = React.lazy(() => import('./pages/AircraftForSaleGallery'));
const Construction = React.lazy(() => import('./pages/Construction'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Auth = React.lazy(() => import('./pages/Auth'));
const Showcase = React.lazy(() => import('./pages/Showcase'));
const Capabilities = React.lazy(() => import('./pages/Capabilities'));
const AircraftForSale = React.lazy(() => import('./pages/AircraftForSale'));
const Kits = React.lazy(() => import('./pages/Kits'));
const OptionsAndConfigurations = React.lazy(() => import('./pages/OptionsAndConfigurations'));
const KitsGallery = React.lazy(() => import('./pages/KitsGallery'));
const Imprint = React.lazy(() => import('./pages/Imprint'));
const PrivacyStatement = React.lazy(() => import('./pages/PrivacyStatement'));

// import Home from './pages/Home';
// import TheStory from './pages/TheStory';
// import AboutCompany from './pages/AboutCompany';
// import DimensionsAndSpecification from './pages/DimensionsAndSpecification';
// import InteriorDesign from './pages/InteriorDesign';
// import BusinessOpportunity from './pages/BusinessOpportunity';
// import AircraftForSaleGallery from './pages/AircraftForSaleGallery';
// import Construction from './pages/Construction';
// import ContactUs from './pages/ContactUs';
// import NotFound from './pages/NotFound';
// import Auth from './pages/Auth';
// import Showcase from './pages/Showcase';
// import Capabilities from './pages/Capabilities';
// import AircraftForSale from './pages/AircraftForSale';
// import Kits from './pages/Kits';
// import OptionsAndConfigurations from './pages/OptionsAndConfigurations';
// import KitsGallery from './pages/KitsGallery';
// import Imprint from './pages/Imprint';
// import PrivacyStatement from './pages/PrivacyStatement';

export default [
    { to: '/', title: 'Introduction', component: Home, hiddenBottom: true },
    { to: '/dimensions-and-specs', title: 'Dimensions & Specs', component: DimensionsAndSpecification},
    { to: '/the-story', title: 'The story', component: TheStory, hiddenBottom: true },
    { to: '/about-company', title: 'About NF-aircraft', component: AboutCompany, hiddenBottom: true},
    { to: '/capabilities', title: 'Engineering', component: Capabilities},
    { to: '/gallery', title: 'Gallery', component: Showcase, hiddenBottom: true },
    { to: '/cockpit', title: 'Cockpit & Cabin', component: InteriorDesign},
    { to: '/technical-information', title: 'Technical information', component: Construction},
    { to: '/options-and-configurations', title: 'Options & Configurations', component: OptionsAndConfigurations},
    { to: '/kits', title: 'Kits', component: Kits, hiddenBottom: true},
    { to: '/kits/gallery', title: 'Kits gallery', component: KitsGallery, hiddenTop: true, hiddenBottom: true},
    { to: '/business-opportunity', title: 'Business opportunity', component: BusinessOpportunity, hiddenBottom: true},
    { to: '/aircraft-for-sale', title: 'Aircraft for sale', component: AircraftForSale, hiddenBottom: true},
    { to: '/aircraft-for-sale/gallery', title: 'Aircraft for sale gallery', component: AircraftForSaleGallery, hiddenTop: true, hiddenBottom: true},
    { to: '/contact-us', title: 'Contact us', component: ContactUs, hiddenBottom: true},
    { to: '/imprint', title: 'Imprint', component: Imprint, hiddenTop: true, hiddenBottom: true },
    { to: '/privacy-statement', title: 'Privacy statement', component: PrivacyStatement, hiddenTop: true, hiddenBottom: true },
    { to: '/404', title: 'Not found', component: NotFound, hiddenTop: true, hiddenBottom: true },
    { to: '/auth', title: 'Authorization', component: Auth, hiddenTop: true, hiddenBottom: true },
];