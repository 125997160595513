import React, { useContext, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import ScrollToTop from './scrollToTop';
import Routes from './routes';
import { store, StateProvider } from './userContext';
import Auth from './auth';
import Loading from './loading';

function AuthWrapper(props) {
  const { dispatch, state } = useContext(store);

  useEffect(() => {
    Auth.onAuthStateChanged(d => {
      if (d) {
        dispatch({ type: 'signIn', data: d });
        // console.log('auth signed in', d);  
      } else {
        dispatch({ type: 'signOut' });
        // console.log('auth signed out');
      }
    });
  }, [])

  return props.children;
}

function App() {
  const routes = Routes.map((r) => <Route exact path={r.to} component={r.component} />);

  useEffect(() => {
    document.getElementById('loading').style.opacity = 0;
    setTimeout(() => {
      document.getElementById('loading').style.display = 'none';
      setTimeout(() => {
        document.getElementById('root').style.height = '100%';
        document.getElementById('root').style.opacity = 1;
      }, 100);
    }, 500);
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        {/* <ScrollMemory /> */}
        <StateProvider>
          <AuthWrapper>
            <ScrollToTop>
              <Switch>
                {routes}
                <Redirect from='*' to='/404' />
              </Switch>
            </ScrollToTop>
          </AuthWrapper>
        </StateProvider>
        {/* <iframe class="actAsDiv" style={{ width: '100%', marginBottom: '-1rem', filter: 'grayscale(100%) invert(1)', height: '20rem' }} frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Norderheverkoog - West 8 25836, Osterhever / Germany&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=15&amp;iwloc=A&amp;output=embed"></iframe> */}
      </Router>
    </Suspense>
  )
}

export default App;
