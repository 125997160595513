import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import db from './db';
import { store } from './userContext';
// import Editor from './Editor';
import Icon from './icon';

const Editor = React.lazy(() => import('./Editor'));

const Wrapper = styled.div`
  opacity: 1;

  :hover {
    animation: ${props => props.contentEditable ? 'none' : 'flickerAnimation 1s infinite'};
    cursor: pointer;
  }

  @keyframes flickerAnimation { /* flame pulses */
    0%   { opacity:1; }
    50%  { opacity:.5; }
    100% { opacity:1; }
  }
`

const Controls = styled.div`
  position: absolute;
  margin-left: -3.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: ${props => props.editableMode ? 'space-between' : 'flex-end'};
  width: 2.5rem;
  cursor: pointer;
  z-index: 99999;
`

const OVersion = (props) => {
  const { state: { auth } } = useContext(store);
  const [lastText, setLastText] = useState();
  const [editableMode, setEditableMode] = useState(false);
  const [controlVisibe, setControlVisible] = useState(false);
  const divEl = useRef(null);

  const { id, pageId, type, plainText, emptyValue } = props;

  const onBlur = (e) => {
    if (auth && divEl && divEl.current && divEl.current.innerText == lastText) {
      setEditableMode(false);
      setControlVisible(false);
    }
  }

  const emptyTemplates = {
    headline: 'Headline',
    title: 'Great title',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse in est ante in nibh. Quam elementum pulvinar etiam non quam lacus. Interdum consectetur libero id faucibus. Facilisis mauris sit amet massa. Amet justo donec enim diam vulputate ut pharetra sit. Enim sit amet venenatis urna. Leo vel orci porta non pulvinar neque laoreet suspendisse. Volutpat consequat mauris nunc congue. Porttitor eget dolor morbi non arcu risus quis varius.'
  }

  const onClick = () => {
    if (auth && !editableMode && divEl.current) {
      setEditableMode(true);
      divEl.current.contentEditable = true;
      divEl.current.focus();
    }
  }

  useEffect(() => {
    const initRealTimeUpdate = async () => {
      if (id && pageId) {
        const ref = await db.collection('pages').doc(pageId).collection(type).doc(id);
        ref.onSnapshot(snap => {
          const d = snap.data();
          let output = null;
          if (d && d.text && d.text.length > 0) {
            output = d.text;
          } else {
            output = emptyTemplates[type] || emptyValue === null ? null : (emptyValue || 'No content');
          }
          if (divEl.current) {
            divEl.current.innerText = output;
          }
          setLastText(output);
        });
      }
    }

    initRealTimeUpdate();
  }, [id, pageId, type]);

  const splitetText = lastText && lastText.split('\n');
  const splitetTextLength = splitetText ? splitetText.length : 0;

  return auth ? <div style={{ display: 'inline-flex' }}
    onMouseOver={() => auth && (setControlVisible(true))}
    onMouseOut={() => auth && !editableMode && (setControlVisible(false))}
    onClick={onClick}
  >
    {controlVisibe ? <Controls editableMode={editableMode}>
      {editableMode ?
        <>
          <Icon name="faCheck" style={{ color: 'green' }} onClick={() => {
            const text = divEl.current.innerText;
            setEditableMode(false);
            setControlVisible(false);
            db.collection('pages').doc(pageId).collection(type).doc(id).set({ text });
          }} />
          <Icon name="faTimes" style={{ color: 'red' }} onClick={() => {
            setEditableMode(false);
            setControlVisible(false);
            divEl.current.innerText = lastText;
          }} />
        </> :
        <Icon name="faPen" style={{ color: '#ccc' }} />
      }
    </Controls> : null}
    <Wrapper
      ref={divEl}
      auth={auth}
      contentEditable={editableMode}
      onBlur={onBlur}
    // onClick={onClick}
    />
  </div> : <>{splitetText && splitetText.map((t, i) => <>{t}{i < splitetTextLength - 1 ? <br /> : null}</>)}</>;
}

const NVersion = ({ id, pageId, type, darkMode }) => {
  const { state: { auth } } = useContext(store);
  const initialValue = [
    {
      children: [
        { text: '' }
      ],
    },
  ];

  const [value, setValue] = useState(initialValue);
  const [inited, setInited] = useState(false);
  const [origin, setOrigin] = useState(initialValue);

  const changeValue = (val) => {
    setInited(true);
    setValue(val);
  }

  const saveValueToDB = () => {
    db.collection(pageId).doc(id).set({ content: value });
    setInited(false);
  }

  const resetValueToOrigin = () => {
    setValue(origin);
    setInited(false);
  }

  useEffect(() => {
    const initRealTimeUpdate = async () => {
      if (id && pageId) {
        const ref = await db.collection(pageId).doc(id);
        ref.onSnapshot(snap => {
          const d = snap.data();
          if (d) {
            setOrigin(d.content);
            setValue(d.content);
          }
        });
      }
    }

    initRealTimeUpdate();
  }, [id, pageId, type]);

  return (
    <>
      {
        auth && inited ?
          <div style={{ position: 'fixed', marginLeft: '-3rem' }}>
            <div style={{ position: 'relative', top: 0, borderRight: '1px dotted #888', paddingRight: '1rem', height: type === 'button' ? '10px' : '100px' }}>
              <div onClick={() => saveValueToDB()}><Icon name="faCheck" style={{ color: 'green' }} /></div>
              <div onClick={() => resetValueToOrigin()}><Icon name="faTimes" style={{ color: 'red' }} /></div>
            </div>
          </div>
          :
          null
      }
      <Editor
        readOnly={auth ? false : true}
        noToolbar={type === 'button' || !auth}
        darkMode={darkMode}
        value={value}
        setValue={changeValue}
      />
    </>
  )

};

export default (props) => {
  return (
    props.type === 'richtext' || props.type === 'button' ?
      <NVersion {...props} />
      :
      <OVersion {...props} />
  )
}
